// BOTON INSTALAR APP

let deferredPrompt;
const addBtn = document.querySelector('.add-button');
//addBtn.style.display = 'none';


document.addEventListener('click', function(event) {
  if (event.target.matches('.instalar_app')) {
    alert('llegoa  instalar paapp')
    addToHomeScreen();
  }
});


$('.instalar_app').hide();
$('.app_instalada').show();

window.addEventListener('beforeinstallprompt', function(event) {
  // not show the default browser install app prompt
  event.preventDefault();
  // add the banner here or make it visible
  // save the event to use it later
  // (it has the important prompt method and userChoice property)
  window.promptEvent = event;
  $('.instalar_app').show();
  $('.app_instalada').hide();
  
});


function addToHomeScreen() {
  // show the install app prompt
  window.promptEvent.prompt();

  // handle the Decline/Accept choice of the user
  window.promptEvent.userChoice.then(function(choiceResult) {
    // hide the prompt banner here
    // …

    if (choiceResult.outcome === 'accepted') {
      console.info('mm User accepted the A2HS prompt');
    } else {
      console.info('mm User dismissed the A2HS prompt');
    }

    window.promptEvent = null;
  }); 
}

