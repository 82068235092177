


function urlB64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}


document.addEventListener('click', function(event) {
  if (event.target.matches('.suscribirse')) {
    
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready
      .then(function(registration) {
        console.log('A service worker is active:', registration.active);
        registration.pushManager.subscribe(
          {
            applicationServerKey: urlB64ToUint8Array("BJ-NNiFxr2Ul0uFLWATQCbm1zExVCU38qQPl4UhVvkGN-FGj0YBeLPLouIkAfkmmXIQwMkhR8nABWJbiUR2RhgA="),
            userVisibleOnly: true
          })
        .then(function(subscription) {
          // NOTIFICACIONES
          // 1) verifico que el navegador tenga soporte a las notificaciones 
          if (!("Notification" in window)) {
            console.error("This browser does not support desktop notification");
          }
          // Let's check whether notification permissions have already been granted
          else if (Notification.permission !== 'denied') {
            console.log("El usuario contesto y otorgo los permisos para recibir notificaciones");        
            console.log("Permission to receive notifications has been granted");
            console.log('OK - Autorizacion Notificaicones-Push :', subscription.toJSON());
            console.log('endpoint:', subscription.endpoint);
            console.log('p256dh:', subscription.getKey('p256dh'));
            console.log('auth:', subscription.getKey('auth'));                
            $.post("/suscribirse", { subscription: subscription.toJSON(), mensaje: "Ejemplo de Params al servidor Rails" });
          }        
      });

      });
    } else {
      console.log('Service workers are not supported.');
    }
  };
});


document.addEventListener('click', function(event) {
  if (event.target.matches('.desuscribirse')) {
    unsubscribe();
  }
});


// function desuscribirse() {
//   alert('desuscribirse');
//   navigator.serviceWorker.ready
//     .then((serviceWorkerRegistration) => {
//       serviceWorkerRegistration.pushManager.getSubscription()
//         .then((subscription) => {
//           if (!subscription) {
//             console.log("Not subscribed, nothing to do.");
//             return;
//           }

//           subscription.unsubscribe()
//             then(function() {
//               console.log("Successfully unsubscribed!.");
//               alert('chauuuuu');
//             })
//             .catch((e) => {
//               logger.error('Error thrown while unsubscribing from push messaging', e);
//             });
//         });
//     });
// }


function unsubscribe() {
  navigator.serviceWorker.ready.then(function(reg) {
    reg.pushManager.getSubscription().then(function(subscription) {
      subscription.unsubscribe().then(function(successful) {
        console.log("unsubscribed");
      }).catch(function(e) {
        console.log("Something went wrong");
      })
    })        
  });
}




// document.addEventListener('click', function(event) {
//   if (event.target.matches('.webpush-button')) {
//     alert ('booton-web clikc');
//     navigator.serviceWorker.ready
//     .then((serviceWorkerRegistration) => {
//       serviceWorkerRegistration.pushManager.getSubscription()
//       .then((subscription) => {
//         $.post('/push', {
//           subscription: subscription.toJSON(),
//           message: 'Usted esta subscripto a notificaciones',
//           title: 'ESTE ES EL TITULO'
//         });
//       });
//     });
//   }
// });



// VERIFICAR ESTADO DE SUSCRIPCION

// 0 = preguntar
// 1 = blqqueado
// 2 = otorgado

var WEBNOTIF_PERMISSION_DEFAULT = 0;
var WEBNOTIF_PERMISSION_DENIED = 1;
var WEBNOTIF_PERMISSION_GRANTED = 2;


  function translateWebNotificationPermissionStatus(status) {
    switch (status) {
      case "granted":
        return WEBNOTIF_PERMISSION_GRANTED;
      case "denied":
        return WEBNOTIF_PERMISSION_DENIED;
      default:
        return WEBNOTIF_PERMISSION_DEFAULT;
    }
  }

  function getNotificationSingleton() {
    return window.Notification;
  }


  function getWebNotificationPermissionStatus() {
    var a =  translateWebNotificationPermissionStatus(getNotificationSingleton().permission);
    //alert(translateWebNotificationPermissionStatus(getNotificationSingleton().permission) + ' permismo otorgado');
    return translateWebNotificationPermissionStatus(getNotificationSingleton().permission);
  }

  getWebNotificationPermissionStatus();
