require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("bootstrap")

import Rails from "@rails/ujs";
window.Rails = Rails;

// Para poder usar jquery en archivo.js.haml
import $ from 'jquery';
global.$ = jQuery;
// segunda opcion probar 
window.jQuery = $;
window.$ = $;



// IMAGENES 
const importAll = (r) => r.keys().map(r)
importAll(require.context('../images', false, /\.(png|jpe?g|svg)$/));

import '../images/ioscor-logo.jpg'

//import '../varios/bootstrap-notify.min'
import '../varios/notificaciones'
import '../varios/instalar_app'
import '../varios/compartir'




// GESTION IMAGES
require.context('../images/', true, /\.(gif|jpg|png|svg|eot|ttf|woff|woff2)$/i)


// GESTION CSS
require ('../stylesheets/application')




$(document).on('turbolinks:load', function(){
  
  
  $("body").click(function(e) {     
    if($(e.target).attr('class') === "alert") {
        $(".alert").show();
    }
    else {
      $(".alert").alert('dispose'); 
      $(".alert").alert('close');
    }
  });


  window.onscroll = function (){
    // Obtenemos la posicion del scroll en pantall
    var scroll = document.documentElement.scrollTop || document.body.scrollTop;

    // Realizamos alguna accion cuando el scroll este entre la posicion 300 y 400
    if(scroll > 300 && scroll < 400){
        $(".alert").alert('dispose'); 
        $(".alert").alert('close');
    }
  }  

      
});



// REGISTRAR EL SERVICE-WORKER

if ('serviceWorker' in navigator) {
  console.log('El navegador Soporta Service-Worker');
  //navigator.serviceWorker.register('/service-worker.js')
  // Subir version en cada cambio
  navigator.serviceWorker.register('/service-worker.js?v=3', {scope: '/'})

    .then(function(registration) {
      console.log('OK - Service-Worker Registrado', registration);
       
  }).catch(function(error) {
    console.log('ERROR - Fallo la Registration del Service-Worker :', error);
  });
}






// SOLO IOSCOR PROVISORIO HASTA DAR DE ALTA LA SUSCRIPCIONES
function urlB64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}


$(document).on('turbolinks:load', function(){
  
  // Notification.requestPermission(function(result) {
  //   if (result === 'granted') {
  //     alert('PERMISO OTORGADO');
  //     $('.suscrib').hide();
  //   }
  //   else if (result === 'denied'){
  //     alert('PERMISO DENEGADO');
  //     $('.suscrib').show();
  //   }
  //   else{  
  //     alert('PREGUNTAR');
  //     $('.suscrib').show();
  //   }  
  // });

  // $("body").click(function(e) {     
  //   if($(e.target).attr('class') === "alert") {
  //       $(".alert").show();
  //   }
  //   else {
  //     $(".alert").alert('dispose'); 
  //     $(".alert").alert('close');
  //   }
  // });

  // Let's check if the browser supports notifications
  if (!("Notification" in window)) {
    console.error("xXXXXXXXXXXXXXXXXXXXXXXXXXX   This browser does not support desktop notification");
  }
  else if (Notification.permission === "granted") {
    
    $('.desuscribirse').show();
    $('.suscribirse').hide();
    $('.desblo_notif').hide();  
    
    // SOLO IOSCOR - ALTA EN TABLA SUSUCRIPCIONES 
    console.log("1111111111111111Permission to receive notifications has been granted");
    navigator.serviceWorker.ready
      .then(function(registration) {
        console.log('A service worker is active:', registration.active);
        registration.pushManager.subscribe(
          {
            applicationServerKey: urlB64ToUint8Array("BJ-NNiFxr2Ul0uFLWATQCbm1zExVCU38qQPl4UhVvkGN-FGj0YBeLPLouIkAfkmmXIQwMkhR8nABWJbiUR2RhgA="),
            userVisibleOnly: true
          })
        .then(function(subscription) {
            console.log("El usuario contesto y otorgo los permisos para recibir notificaciones");        
            console.log("Permission to receive notifications has been granted");
            console.log('OK - Autorizacion Notificaicones-Push :', subscription.toJSON());
            console.log('endpoint:', subscription.endpoint);
            console.log('p256dh:', subscription.getKey('p256dh'));
            console.log('auth:', subscription.getKey('auth'));                
            $.post("/suscribirse", { subscription: subscription.toJSON(), mensaje: "Ejemplo de Params al servidor Rails" });                  
      });
    });

  }
  else if (Notification.permission === 'denied') {
        //alert('MOSTRAR BANNER NOTIFICAICONES - DENEGADAS')
        $('.desblo_notif').show();
        $('.suscribirse').hide();
        $('.desuscribirse').hide();
      }
  else {
      $('.suscribirse').show();
      $('.desuscribirse').hide();
      $('.desblo_notif').hide();
      //alert('MOSTRAR banner - CONFIGURACION PREGUNTAR')
  };


  window.onscroll = function (){
    // Obtenemos la posicion del scroll en pantall
    var scroll = document.documentElement.scrollTop || document.body.scrollTop;

    // Realizamos alguna accion cuando el scroll este entre la posicion 300 y 400
    if(scroll > 300 && scroll < 400){
        $(".alert").alert('dispose'); 
        $(".alert").alert('close');
    }
  }

      
});
  







// document.addEventListener('click', function(event) {
//   if (event.target.matches('.suscribirse')) {
    
//     if ('serviceWorker' in navigator) {
//       navigator.serviceWorker.ready
//       .then(function(registration) {
//         console.log('A service worker is active:', registration.active);
//         registration.pushManager.subscribe(
//           {
//             applicationServerKey: urlB64ToUint8Array("BJ-NNiFxr2Ul0uFLWATQCbm1zExVCU38qQPl4UhVvkGN-FGj0YBeLPLouIkAfkmmXIQwMkhR8nABWJbiUR2RhgA="),
//             userVisibleOnly: true
//           })
//         .then(function(subscription) {
//           // NOTIFICACIONES
//           // 1) verifico que el navegador tenga soporte a las notificaciones 
//           if (!("Notification" in window)) {
//           }
//           // Let's check whether notification permissions have already been granted
//           else if (Notification.permission !== 'denied') {
//             console.log("El usuario contesto y otorgo los permisos para recibir notificaciones");        
//             console.error("This browser does not support desktop notification");
//             console.log("Permission to receive notifications has been granted");
//             console.log('OK - Autorizacion Notificaicones-Push :', subscription.toJSON());
//             console.log('endpoint:', subscription.endpoint);
//             console.log('p256dh:', subscription.getKey('p256dh'));
//             console.log('auth:', subscription.getKey('auth'));                
//             $.post("/subscribe", { subscription: subscription.toJSON(), mensaje: "Ejemplo de Params al servidor Rails" });
//           }        
//       });

//       });
//     } else {
//       console.log('Service workers are not supported.');
//     }
//   };
// });


 

// // NOTIFICACIONES
// // 1) verifico que el navegador tenga soporte a las notificaciones 
// if (!("Notification" in window)) {
//   console.error("This browser does not support desktop notification");
// }
// // Let's check whether notification permissions have already been granted
// else if (Notification.permission === "granted") {
//   console.log("El usuario contesto y otorgo los permisos para recibir notificaciones");
// }
// // Otherwise, we need to ask the user for permission
// else if (Notification.permission !== 'denied') {
//   Notification.requestPermission(function (permission) {
//   // If the user accepts, let's create a notification
//   if (permission === "granted") {
//     console.log("Permission to receive notifications has been granted");
//     }
// });
// }





// EJEMPLO DE PROMESA

// const promise = new Promise((resolve, reject) => {
//   const number = Math.floor(Math.random() * 10);

//   setTimeout(
//     () => number > 5
//       ? resolve(number)
//       : reject(new Error('Menor a 5')),
//     1000
//   );
// });

// promise
//   .then(number => console.log('promesa: ' + number))
//   .catch(error => console.error(error));


document.addEventListener('click', function(event) {
  if (event.target.matches('.webpush-button')) {
    alert ('booton-web clikc');
    navigator.serviceWorker.ready
    .then((serviceWorkerRegistration) => {
      serviceWorkerRegistration.pushManager.getSubscription()
      .then((subscription) => {
        $.post('/suscribirse', {
          subscription: subscription.toJSON(),
          message: 'Usted esta subscripto a notificaciones',
          title: 'ESTE ES EL TITULO'
        });
      });
    });
  }
});  




