// BOTON COMPARTIR
document.addEventListener('click', function(event) {
  if (event.target.matches('.compartir')) {
    if (navigator.share) {
      navigator
        .share({
          title: "EJEMPLO DE COMPARTIR ALGO ",
          text: "ESTOY POR COMPARTIR ESTO",
          url: "https://tn.com.ar"
        })
        .then(() => {
          console.log("Se compartio correctamente con SHARE WEB API .");
        })
        .catch(error => {
          console.log("ERROR AL COMPARTI CON WEB SHARE API (soporta el navegador pero algo salio mal)", error);
        });
    } else {
      console.log("El navegador no soporta The Web Share API.");
      alert('El navegador no soportar Web Share Api  MOSTRAR BOTONES DE COMPARTIR');
    }
  }
});


document.addEventListener('click', function(event) {
  if (event.target.matches('.compartir_file')) {
    if (navigator.canShare && navigator.canShare({ files: filesArray })) {
      navigator.share({
        files: filesArray,
        title: 'Ejemplo de Compartir un archivo',
        text: 'TEXTO DEL ejemplo de compartir un archivo.',
      })
      .then(() => console.log('Share  files es successful. reqeuete!'))
      .catch((error) => console.log('Sharing failed', error));
    } else {
      console.log('Su navegador no soporta compartir archivos!');
      alert('El navegador no soportar Web Share Api compartir_file ');
    }  
  }
});



// Web Share - Permitted File Extensions in Chromium

// Application
//                     "pdf"  -  application/pdf (from Chromium 93)
// Audio
//                     "flac"  -  audio/flac
//                     "m4a"  -  audio/x-m4a
//                     "mp3"  -  audio/mpeg (also accepts audio/mp3)
//                     "oga"  -  audio/ogg
//                     "ogg"  -  audio/ogg
//                     "opus"  -  audio/ogg
//                     "wav"  -  audio/wav
//                     "weba"  -  audio/webm
// Image
//                     "bmp"  -  image/bmp
//                     "gif"  -  image/gif
//                     "ico"  -  image/x-icon
//                     "jfif"  -  image/jpeg
//                     "jpeg"  -  image/jpeg
//                     "jpg"  -  image/jpeg
//                     "pjp"  -  image/jpeg
//                     "pjpeg"  -  image/jpeg
//                     "png"  -  image/png
//                     "svg"  -  image/svg+xml
//                     "svgz"  -  image/svg+xml
//                     "tif"  -  image/tiff
//                     "tiff"  -  image/tiff
//                     "webp"  -  image/webp
//                     "xbm" - image/x-xbitmap

